import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import tdcColors from '@tdcerhverv/parrotfish/dist/colors-design-token.js'
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Hidden,
  SvgIcon,
  IconButton,
} from '@material-ui/core'
import { Tabs } from '../components/Tabs/Tabs'
import { StockStatusIndicator } from '../components/StockStatusIndicator/StockStatusIndicator'
import { translate } from '../helpers/translate'
import { useBasket } from '../modules/basket'
import { useProductList } from '../store/product-list'
import { GetHelp } from '../components/GetHelp'
import { ImageGallery } from '../components/ImageGallery'
import { VariantSelector } from '../components/VariantSelector'
import { routes } from '../components/Router/routes'
import Layout from '../Layout/Layout'
import Seo from '../components/Seo'
import { getErhvervsCenterAPIUrl } from '../helpers/api-url'
import { usePageViewAnalytics } from '../hooks/useAnalytics'
import Charger from '../../static/charger_included.svg'
import NoCharger from '../../static/charger_not_included.svg'
import PopOverInfo from '../components/PopOverInfo'
import ArrowLeftIcon from '../icons/generated/ArrowLeftIcon'

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  root: {
    position: 'relative',
  },
  rootLoading: {
    '&:after': {
      position: 'absolute',
      zIndex: 10,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'rgba(255,255,255,.5)',
      content: "''",
    },
  },
  galleryContainer: {
    [breakpoints.up('sm')]: {
      order: 0,
    },
  },
  mainInfoContainer: {
    [breakpoints.up('sm')]: {
      order: 1,
    },
  },
  brandName: {
    color: tdcColors.greyMediumDark,
  },
  productName: {
    ...typography.h3,
    marginTop: 0,
    marginBottom: spacing(0.5),
  },
  priceLabel: {
    color: tdcColors.greyMediumDark,
  },
  tableSpecNameCell: {
    width: '40%',
  },
  tableSpecCellOdd: {},
  tableSpecCellEven: {
    background: tdcColors.greyLightest,
  },
  chargerInfo: {
    background: tdcColors.greyLight,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px 8px 56px',
    borderRadius: 6,
    height: 72,
    marginTop: 24,
    position: 'relative',
  },
  chargerIcon: {
    marginTop: 2,
    width: 82,
    height: 56,
    left: 8,
    position: 'absolute',
  },
  chargerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 18,
    width: 30,
    height: 28,
  },
}))

const ProductViewTemplate = ({ data }) => {
  const {
    Brand,
    Delivery,
    Description,
    FeatureGroups,
    Price,
    FormattedPrice,
    Images,
    Name,
    VariantNo,
    Variants,
    Networks,
    ChargerIncluded,
    FormattedMaximumPower,
    FormattedMinimumPower,
    ShowChargerInformation,
  } = data.product
  const classes = useStyles()
  const { serializedQueryString } = useProductList()
  const productBrand = Brand.charAt(0) + Brand.slice(1).toLowerCase()
  const chargerTitle = `Oplader medfølger${!ChargerIncluded ? ' ikke' : ''}`

  //   const { productId = '' } = useParams<{ [key in Params]: string }>();
  const productId = VariantNo //probably not correct to use like this
  const { addToBasket, openMiniBasket } = useBasket(state => ({
    addToBasket: state.addToBasket,
    openMiniBasket: state.openMiniBasket,
  }))

  const lines = useBasket(state => state.lines || [])
  const [delivery, setDelivery] = useState(Delivery)
  const [quantityInBasket, setQuantityInBasket] = useState(
    lines.find(({ variantId }) => variantId === productId)?.amount || 0,
  )

  useEffect((): void => {
    setQuantityInBasket(
      lines.find(({ variantId }) => variantId === productId)?.amount || 0,
    )
  }, [lines, productId])

  useEffect(() => {
    // update delivery status in case product is sold out or restocked since last gatsby build
    fetch(`${getErhvervsCenterAPIUrl()}publicproducts/${VariantNo}`).then(res =>
      res.json().then(product => {
        setDelivery(product.Delivery)
      }),
    )
  }, [])

  const changeVariant = (idx: number, variantID: string): void => {
    const currentVariant = Variants?.find(
      variant => variant.VariantNo === productId,
    )?.VariantName

    const newlyChosenVariant = (Variants || [])[idx]

    // We'll try replacing the current color with the newly selected one, which should yield the correct URL for us.
    const processedName =
      currentVariant && newlyChosenVariant && newlyChosenVariant.VariantName
        ? (Name || '').replace(currentVariant, newlyChosenVariant.VariantName)
        : Name

    // Calculate new URL for the given variant ID
    const newPath = routes.productPage.build(variantID, processedName)

    // Replace the current location with the new URL for a seamless transition
    navigate(newPath)
  }

  const currentVariantIndex = (Variants || []).findIndex(
    variantData => variantData.VariantNo === productId,
  )

  // Adobe Analytics - Global Variables
  usePageViewAnalytics({
    trackingPageInfo: {
      pageName: `${Brand} ${Name}`,
      siteSection: 'shop',
      subSection1: 'product',
      pageType: 'product detail',
      id: `product-${VariantNo}`,
      pageVariant: 'SMB',
    },
    trackingProductsInfo: {
      customEvent: 'view_item',
      items: [
        {
          brand: productBrand,
          name: Name,
          id: VariantNo,
          quantity: `${quantityInBasket}`,
          price: Price,
        },
      ],
    },
    dependency: [VariantNo],
  })

  const BackToListButton = () => (
    <Button
      component={Link}
      to={`/${serializedQueryString ? `?${serializedQueryString}` : ''}`}
      variant="text"
      size="large"
    >
      <IconButton color="inherit" size="small">
        <SvgIcon
          component={ArrowLeftIcon}
          fontSize="inherit"
          viewBox={'0 0 48 48'}
        />
      </IconButton>
      {translate('goBack')}
    </Button>
  )

  const ProductDetails = () => (
    <>
      <Typography variant="h6" className={classes.brandName} component="div">
        {Brand}
      </Typography>
      <Typography variant="h3" component="h1" className={classes.productName}>
        {Name}
      </Typography>
      <Box mb={3}>
        <Typography
          variant="caption"
          gutterBottom
          className={classes.priceLabel}
        >
          {`${translate('sku')} ${VariantNo}`}
        </Typography>
      </Box>
      <VariantSelector
        variants={Variants || []}
        setSelectedIndex={changeVariant}
        selectedIndex={Math.max(0, currentVariantIndex)}
        large
      />
    </>
  )
  const PriceDisplay = () => (
    <>
      <Typography variant="h5" component="div">
        {FormattedPrice}
      </Typography>
      <Typography variant="caption" gutterBottom className={classes.priceLabel}>
        {translate('exVatLabel')}
      </Typography>
    </>
  )
  const CallToAction = () => (
    <Grid container justifyContent="flex-start" spacing={2}>
      <Grid item>
        <Button
          variant="contained"
          disabled={
            delivery?.StockCount === 0 ||
            delivery?.StockCount === quantityInBasket
          }
          onClick={() => {
            addToBasket(productId, 1)
            openMiniBasket()
          }}
          color="primary"
          size="large"
        >
          {delivery?.StockCount === 0
            ? translate('basket.outOfStock')
            : translate('basket.addToBasket')}
        </Button>
      </Grid>
    </Grid>
  )
  const RenderedImageGallery = () => (
    <ImageGallery
      has5GBadge={Networks.includes('5G')}
      variantNo={VariantNo}
      images={Images}
    />
  )

  const DescriptionContent = (): React.ReactNode => {
    return !!Description ? (
      <Typography
        dangerouslySetInnerHTML={{
          __html: Description.replaceAll(/\n/g, '<br/>'),
        }}
        variant="body2"
        component="div"
      />
    ) : (
      <></>
    )
  }

  const hasSpecifications = FeatureGroups?.length > 0
  const SpecificationContent = () => {
    return hasSpecifications ? (
      <Box>
        {FeatureGroups.map((group, gIdx) => (
          <React.Fragment key={gIdx}>
            <Typography variant="subtitle1">{group.Name}</Typography>
            <Box mb={4} mt={2}>
              <TableContainer component={Paper} variant={'outlined'}>
                <Table aria-label={group.Name} size="small">
                  <TableBody>
                    {group.Specifications?.map((specification, sIdx) => (
                      <TableRow key={`${specification.Name}-${sIdx}`}>
                        <TableCell
                          component="th"
                          className={`${classes.tableSpecNameCell} ${
                            sIdx % 2
                              ? classes.tableSpecCellEven
                              : classes.tableSpecCellOdd
                          }`}
                        >
                          <Typography variant="subtitle2">
                            {specification.Name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={
                            sIdx % 2
                              ? classes.tableSpecCellEven
                              : classes.tableSpecCellOdd
                          }
                        >
                          <Typography variant="body2">
                            {specification.Value}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    ) : (
      <></>
    )
  }

  return (
    <Layout>
      <Seo
        lang="da"
        title={`${Brand} ${Name}`}
        description="TDC Erhverv shop"
      />
      <article
        className={classes.root} /* ${loading ? ` ${classes.rootLoading} */
      >
        <Grid container justifyContent={'space-between'}>
          <BackToListButton />
        </Grid>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12} sm={6} className={classes.mainInfoContainer}>
            <ProductDetails />
            <Box mb={3} mt={3}>
              <PriceDisplay />
              {delivery && (
                <Box mt={2} mb={3}>
                  <StockStatusIndicator
                    color={delivery.Status}
                    text={delivery.Text}
                  />
                </Box>
              )}
              <CallToAction />

              {ShowChargerInformation && (
                <Box className={classes.chargerInfo}>
                  <SvgIcon
                    viewBox="0 0 82 56"
                    className={classes.chargerIcon}
                    component={ChargerIncluded ? Charger : NoCharger}
                  />
                  <Box className={classes.chargerContainer}>
                    <Typography
                      style={{ fontSize: 6, lineHeight: 1, fontWeight: '700' }}
                    >
                      {`${FormattedMinimumPower} - ${FormattedMaximumPower}`}
                    </Typography>
                    <Typography
                      style={{ fontSize: 6, lineHeight: 1, fontWeight: '700' }}
                    >
                      W
                    </Typography>
                    <Typography
                      style={{ fontSize: 6, lineHeight: 1, fontWeight: '700' }}
                    >
                      USB PD
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 14,
                    }}
                  >
                    <Typography variant="subtitle2">{chargerTitle}</Typography>
                    <div>
                      <Typography variant="caption">
                        {`Produktet bruger ${FormattedMinimumPower} - ${FormattedMaximumPower} W USB PD`}
                      </Typography>

                      <PopOverInfo
                        text={`${chargerTitle}. Opladerens effekt (W) skal være
                            mellem det angivne minimum, som enheden kræver, og
                            den angivne maksimale effekt, som kræves for at opnå
                            maksimal opladningshastighed. USB PD hurtig
                            opladning`}
                      />
                    </div>
                  </Box>
                </Box>
              )}

              <Hidden smUp>
                <Box mb={5}>
                  <RenderedImageGallery />
                </Box>
                <Box mt={2} mb={5}>
                  <GetHelp wide />
                </Box>
              </Hidden>
              <Box mt={5}>
                {!!Description && hasSpecifications && (
                  <Tabs
                    tabs={[
                      {
                        title: translate('product.description'),
                        content: DescriptionContent(),
                      },
                      {
                        title: translate('product.specifications'),
                        content: SpecificationContent(),
                      },
                    ]}
                    idPrefix={'productTabs'}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={5} className={classes.galleryContainer}>
              <RenderedImageGallery />
              <Box mt={2} mb={5}>
                <GetHelp wide />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </article>
    </Layout>
  )
}

export default ProductViewTemplate

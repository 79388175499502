import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import Expand_left from '@tdcerhverv/parrotfish/dist/icons/Icons/Expand_left.svg'
import Expand_right from '@tdcerhverv/parrotfish/dist/icons/Icons/Expand_right.svg'

const useStyles = makeStyles(theme => ({
  carouselContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'hidden',
    scrollSnapType: 'x mandatory',
    width: '90%',
    margin: '0 auto',
  },
  carouselItem: {
    flex: '0 0 auto',
    width: 'calc(100% / 4)',

    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% / 3)',
    },
  },
  arrowButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: 8,
  },
  arrowButtonSvg: {
    width: '0.8em',
    height: '0.8em',
  },
  prevButton: {
    left: '20px',
  },
  nextButton: {
    right: '20px',
  },
}))

const Carousel = ({ children, thumbnailIndex, setThumbnailIndex }) => {
  const classes = useStyles()
  const scrollRef = useRef(null)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const itemsPerScroll = isDesktop ? 4 : 3

  useEffect(() => {
    const scrollToIndex = () => {
      const itemWidth = scrollRef.current.children[0].offsetWidth
      const scrollPosition = itemWidth * thumbnailIndex
      scrollRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      })
    }
    scrollToIndex()
  }, [thumbnailIndex])

  const handlePrevClick = () => {
    setThumbnailIndex(prevIndex => {
      const newIndex = prevIndex - itemsPerScroll
      return newIndex < 0
        ? Math.max(0, children.length - itemsPerScroll)
        : newIndex
    })
  }

  const handleNextClick = () => {
    setThumbnailIndex(prevIndex => {
      const newIndex = prevIndex + itemsPerScroll
      return newIndex >= children.length ? 0 : newIndex
    })
  }

  const showArrows = isDesktop ? children.length > 4 : children.length > 3

  return (
    <Box width="100%" padding="0 60px">
      {showArrows && (
        <IconButton
          className={`${classes.arrowButton} ${classes.prevButton}`}
          onClick={handlePrevClick}
        >
          <SvgIcon
            component={Expand_left}
            className={classes.arrowButtonSvg}
            fontSize="inherit"
            viewBox={'0 0 48 48'}
          />
        </IconButton>
      )}
      <Box className={classes.carouselContainer} ref={scrollRef}>
        {children.map((child, index) => (
          <Box key={index} className={classes.carouselItem}>
            {child}
          </Box>
        ))}
      </Box>
      {showArrows && (
        <IconButton
          className={`${classes.arrowButton} ${classes.nextButton}`}
          onClick={handleNextClick}
        >
          <SvgIcon
            component={Expand_right}
            className={classes.arrowButtonSvg}
            fontSize="inherit"
            viewBox={'0 0 48 48'}
          />
        </IconButton>
      )}
    </Box>
  )
}

export default Carousel
